import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import Spline from '@splinetool/react-spline';

import { pages, workParams } from '../const';

import './home.css';
import Div100vh from 'react-div-100vh'

import splineFile from './assets/spline.splinecode';
import splineFilDesktop from './assets/spline_desktop.splinecode';

import splinePreview from './assets/spline_preview-2.jpg';
import splinePreview_webp from './assets/spline_preview-2.webp';

import splinePreview_mob from './assets/spline_preview-2_mob.jpg';
import splinePreview_mob_webp from './assets/spline_preview-2_mob.webp';

import topLogoImg from './assets/logo.gif';
import {ModalAbout} from "../components/ModalAbout";
import {SocialLinks} from "../components/SocialLinks";
import {ModalContacts} from "../components/ModalContacts";
import { Scrollbars } from 'react-custom-scrollbars-2'

const NavItem = ({ cssPrefix, label, onClick, onMouseEnter, onMouseLeave, children }) => {
    const [hovered, setIsHovered] = useState('');
    return <div
        className={`nav-item nav-item-${cssPrefix} ${hovered}`}>

        <div className='nav-work-item'
            onMouseEnter={() => {
                setIsHovered('nav-item-hovered');
                if (onMouseEnter) onMouseEnter();
            }}
            onMouseLeave={() => {
                setIsHovered('nav-item-faded');
                if (onMouseLeave) onMouseLeave();
            }}
            >
            <span className='nav-item-label nav-item-label__desktop' onClick={onClick}>
                <span>{label}</span>
            </span>
            {children}
        </div>
    </div>
};

const CenterLogo = () => {
    const [onloadCube, setOnloadCube] = useState(false);
    const [mobile, setMobile] = useState(false);
    const [desktop, setDesktop] = useState(false);

    useEffect(() => {
        if (window.matchMedia("(max-width: 768px)").matches) {
            setMobile(true);
        } else {
            setDesktop(true);
        }
    }, [])

    return (
        <div className='center-logo-container'>
            <div className={`spline-preview spline-preview__mobile ${ onloadCube ? '_hide' : '' }`}>
                <picture>
                    <source srcSet={splinePreview_mob_webp} type='image/webp' media="(max-width: 768px)"/>
                    <source srcSet={splinePreview_mob} type='image/jpg' media="(max-width: 768px)"/>

                    <source srcSet={splinePreview_webp} type='image/webp'/>
                    <img src={splinePreview} alt=""/>
                </picture>
            </div>
            {mobile &&
                <Spline style={{marginTop: '-50px'}} scene={splineFile} onLoad={() => {
                    setOnloadCube(true)
                    console.log('loaded');
                }}
                />
            }

            {desktop &&
                <Spline scene={splineFilDesktop} onLoad={() => {
                    setOnloadCube(true)
                    console.log('loaded');
                }}
                />
            }
        </div>
    );
};

const WorkSubitems = ({ isVisible }) => {
    const navigate = useNavigate();
    const items = [
        { text: 'All', link: `${pages.work}` },
        { text: 'Music Video', link: `${pages.work}?topic=${workParams.musicVideo}` },
        { text: 'Commercial', link: `${pages.work}?topic=${workParams.commercial}` },
        { text: 'Reel', link: `${pages.work}?topic=${workParams.reel}` },
    ];
    const [renderCount, setInitialShown] = useState(0);
    useEffect(() => {
        setInitialShown(renderCount + 1);
    }, [isVisible]);

    return <ul className={`nav-subitems ${(isVisible) ? 'active' : ''}`}>
        {items.map(({ text, link }, n) =>
            <li
                key={text}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate(link);
                }}
            >
                <p style={{transitionDelay: `${n * 150}ms`}}>{text}</p>
            </li>)}
    </ul>;
};

export const Modal = ({ scrollable, isVisible, hideModal, withoutFade, crossHidden, children, className }) => (
    <div style={{ display: isVisible ? 'block' : 'none' }} onClick={() => hideModal()} className={className}>
        <div className={`modal-container ${!withoutFade ? 'modal-container-fade' : ''}`}>
            {!crossHidden && <div className="modal-header">
                <button onClick={hideModal}>X</button>
            </div>}
            {scrollable ? (
              <Scrollbars
                renderThumbVertical={props => < div {...props} className="thumb-vertical"/>}
                renderTrackVertical={props => < div {...props} className="track-vertical"/>}>
              <div className='modal-content'>
                  {children}
              </div>

              </Scrollbars>
            ) : (
            <div className='modal-content'>
                {children}
            </div>
            )}
        </div>
    </div>
)

export const Logo = () => {
    const navigate = useNavigate();
    return(
        <NavItem cssPrefix='top-left nav-logo-top' label={<div className='nav-logo' onClick={() => navigate('/')}>
            <img src={topLogoImg} alt='H' />IMIA</div>
        }/>
    )
};

export const Copyright = () => <p className="copyright-text">
    © Copyright {new Date().getFullYear()} HIMIA.
</p>;


export const HomePage = () => {
    const [aboutModalVisible, setAboutModalVisibility] = useState(false);
    const [contactsModalVisible, setContactModalVisibility] = useState(false);
    const [workSubitemsVisible, setWorkSubitemsVisibility] = useState(false);
    const [hiddenMenuActive, setHiddenMenuActive] = useState(false);
    const navigate = useNavigate();

    const handlerBurgerClick = () => {
        setHiddenMenuActive(!hiddenMenuActive);
        setAboutModalVisibility(false);
        setContactModalVisibility(false);
    }

    return <Div100vh className='page-container home'>
        <Logo />
        <div />
        <NavItem cssPrefix='top-right'
                 label="Work"
                 onClick={() => navigate(pages.work)}
                 onMouseEnter={() => setWorkSubitemsVisibility(true)}
                 onMouseLeave={() => setWorkSubitemsVisibility(false)}
        >
            <WorkSubitems isVisible={workSubitemsVisible} />
            <div className={`burger ${hiddenMenuActive ? 'active' : ''}`}
                 onClick={handlerBurgerClick}
            >
                <span></span>
                <span></span>
                <span></span>
            </div>
        </NavItem>
        <div className={`hidden-menu modal-container-fade ${hiddenMenuActive ? 'active' : '' }`}
             onClick={() => setHiddenMenuActive(!hiddenMenuActive)}
        >
            <div className="hidden-menu__content">
                <p onClick={() => navigate(pages.work)}>Work</p>
                <p onClick={() => setAboutModalVisibility(true)}>About</p>
                <p onClick={() => setContactModalVisibility(true)}>Contact</p>
            </div>
        </div>

        <CenterLogo />

        <NavItem cssPrefix='bottom-left' label="Contact" onClick={() => setContactModalVisibility(true)} />
        <SocialLinks />
        <NavItem cssPrefix='bottom-right' label="About" onClick={() => setAboutModalVisibility(true)} />

        <ModalAbout
            isVisible={aboutModalVisible}
            hideModal={() => setAboutModalVisibility(false)}
        />
        <ModalContacts
            isVisible={contactsModalVisible}
            hideModal={() => setContactModalVisibility(false)}
        />

        <footer className='footer-home'>
            <div className='work-footer'>
                <Copyright />
            </div>
        </footer>
    </Div100vh>
};
