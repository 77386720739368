import React, {useState} from "react";
import {Modal} from "../Home";
import {SocialLinks} from "./SocialLinks";

export const ModalContacts = ({isVisible, hideModal}) => {
    return (
        isVisible && <Modal
            isVisible={isVisible}
            hideModal={hideModal}
            className='modal-contacts'
        >
            <a href="mailto:hello@himia.digital" className="modal-anim-link mail">hello@himia.digital</a>
            {/* <ul> */}
            {/*     <li><a href=" https://wa.me/+380957745972" className='modal-anim-link'>+380957745972</a></li> */}
            {/*     <li><a href=" https://wa.me/+48726147873" className='modal-anim-link'>+48726147873</a></li> */}
            {/* </ul> */}
            <SocialLinks/>
        </Modal>
    )
}
