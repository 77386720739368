import React, {useState} from "react";
import {Modal} from "../Home";

export const ModalAbout = ({isVisible, hideModal}) => {
    return (
        isVisible && <Modal
            isVisible={isVisible}
            hideModal={hideModal}
            className='modal-about'
        >
            <div className="text">
                <p className='text-item'>
                    <strong className="_bigger">HIMIA </strong>
                    <span className="_bigger">is a VFX/Creative team.</span>
                </p>
                <p className='text-item'>
                    It's been 10 years since we began our journey and now we find ourselves here.
                    Our goal is to bring art to life and execute your ideas with passion and taste.
                    We are HIMIA, a visionary team with experience working with notable celebrities and brands.
                    Our job is to provide post-production services and solutions to breathe life into your products.
                    Our professionalism, hard work, and dedication are our main tools and the key to excellent results.
                </p>
                <div className='text-item'>
                    <p className="_bigger subtitle">
                        SERVICES
                    </p>
                    <p className='text-item'>post-production/VFX, 3D animation, CGI</p>
                </div>
            </div>
            <div className="video">
                <iframe title="vimeo video" src="https://player.vimeo.com/video/811839110" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"/>
            </div>
        </Modal>
    )
}
