import {SocialIcon} from "react-social-icons";
import React from "react";

export const SocialLinks = () =>{
    return (
        <div className='social-links-container'>
            <div className='social-links'>
                <SocialIcon url="https://vimeo.com/user125657309" target="_blank" fgColor="#E6E6E8" bgColor='transparent'/>
                <SocialIcon url="https://www.instagram.com/himia.digital/" target="_blank" fgColor="#E6E6E8" bgColor='transparent' />
            </div>
        </div>
    );
}
