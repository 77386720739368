import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { pages, workParams } from '../const';
import { Logo, Modal, Copyright } from '../Home';

import playIcon from './play.png';
import './work.css';
import { Scrollbars } from 'react-custom-scrollbars-2';
import {ModalAbout} from "../components/ModalAbout";
import {ModalContacts} from "../components/ModalContacts";
import Div100vh from "react-div-100vh";
import useVH from "react-vh";

const contentIds = [
    // {
    //     platform: 'youtube',
    //     id: 'FAVm8j0xOlM',
    //     category: workParams.commercial,
    //     title: 'TEST',
    //     previewTitle: 'Apple',
    //     desc: 'Client: <span class="_color-white">Apple</span>, Director: <span class="_color-white">BRTHR</span> </br> <small>VFX, CGI</small>',
    //     poster: require('./assets/apple.jpg'),
    //     additionalVideosIds: []
    // },
    {
        platform: 'youtube',
        id: "CQdFOdyTGgc",
        category: workParams.musicVideo,
        title: 'Moses Sumney - Vintage',
        previewTitle: 'Moses Sumney - Vintage',
        desc: 'Director: <span class="_color-white">Moses Sumney</span> </br> <small>VFX</small>',
        poster: require('./assets/moses_half.jpg'),
        additionalVideosIds: []
    },
    {
        platform: 'vimeo',
        id: 938556780,
        category: workParams.commercial,
        title: 'Coachella On YouTube',
        previewTitle: 'COACHELLA x YouTube',
        desc: 'Client: <span class="_color-white">Coachella, YouTube</span>, Director: <span class="_color-white">BRTHR</span> </br> <small>VFX, CGI, Animation</small>',
        poster: require('./assets/coachella_youtube.jpg'),
        additionalVideosIds: []
    },
    {
        platform: 'youtube',
        id: "jIDSHW5tIrQ",
        category: workParams.commercial,
        title: 'The Weeknd x Fortnite Festival Trailer',
        previewTitle: 'Fortnite x WEEKND',
        desc: 'Client: <span class="_color-white">Epic Games</span>, Director: <span class="_color-white">BRTHR</span> </br> <small>VFX, Animation</small>',
        poster: require('./assets/fortnite.jpg'),
        additionalVideosIds: []
    },
    {
        platform: 'vimeo',
        id: 938556195,
        category: workParams.commercial,
        title: 'PUMA x MELO MB.03',
        previewTitle: 'PUMA x MELO',
        desc: 'Client: <span class="_color-white">PUMA</span>, Director: <span class="_color-white">BRTHR</span> </br> <small>VFX, Animation</small>',
        poster: require('./assets/puma_melo.jpg'),
        additionalVideosIds: []
    },
    {
        platform: 'vimeo',
        id: 867451990,
        category: workParams.commercial,
        title: 'Reebok x MSCHF Pump Omni Zone LX',
        previewTitle: 'Reebok x MSCHF',
        desc: 'Client: <span class="_color-white">Reebok</span>, Director: <span class="_color-white">BRTHR</span> </br> <small>VFX, Animation</small>',
        poster: require('./assets/reebok_mschf.jpg'),
        additionalVideosIds: []
    },
    {
        platform: 'vimeo',
        id: 854897793,
        category: workParams.commercial,
        title: 'H&M x Disney',
        previewTitle: 'H&M x Disney',
        desc: 'Client: <span class="_color-white">H&M</span>, Director: <span class="_color-white">BRTHR</span> </br> <small>VFX, Animation</small>',
        poster: require('./assets/hm_disney.jpg'),
        additionalVideosIds: []
    },
    {
        platform: 'vimeo',
        id: 834369636,
        category: workParams.commercial,
        title: 'Summer of Joy, Youth, Freedom',
        previewTitle: 'T-Mobile',
        desc: 'Client: <span class="_color-white">Deutsche Telekom</span>, Director: <span class="_color-white">BRTHR</span> </br> <small>VFX, CGI, Animation</small>',
        poster: require('./assets/t_mobile.jpg'),
        additionalVideosIds: []
    },
    {
        platform: 'vimeo',
        id: 792265712,
        category: workParams.commercial,
        title: 'SAVAGE x FENTY By Rihanna',
        previewTitle: 'SAVAGE x FENTY By Rihanna',
        desc: 'Director: <span class="_color-white">BRTHR</span> </br> <small>VFX, Animation</small>',
        poster: require('./assets/fenty.jpg'),
        additionalVideosIds: []
    },
    {
        platform: 'vimeo',
        id: 761994425,
        category: workParams.musicVideo,
        title: 'Peter Fox - Zukunft Pink',
        previewTitle: 'Peter Fox - Zukunft Pink',
        desc: 'Director: <span class="_color-white">Jakob Grunert</span> </br> <small>VFX, CGI</small>',
        poster: require('./assets/peter_fox.jpg'),
        additionalVideosIds: []
    },
    {
        platform: 'vimeo',
        id: 845509712,
        category: workParams.commercial,
        title: 'Overwatch 2 - Unleash Hope',
        previewTitle: 'Overwatch 2',
        desc: 'Client: <span class="_color-white">Blizzard</span>, Director: <span class="_color-white">BRTHR</span> </br> <small>VFX, CGI, Animation</small>',
        poster: require('./assets/overwatch_2.jpg'),
        additionalVideosIds: []
    },
    {
        platform: 'vimeo',
        id: 755182377,
        category: workParams.commercial,
        title: 'BMW XM',
        previewTitle: 'BMW XM',
        desc: 'Client: <span class="_color-white">BMW</span>, Director: <span class="_color-white">BRTHR</span> </br> <small>VFX, CGI, Animation</small>',
        poster: require('./assets/bmw_xm.jpg'),
        additionalVideosIds: []
    },
    {
        platform: 'vimeo',
        id: 754686240,
        category: workParams.commercial,
        title: 'le Grand Magasin de Tous',
        previewTitle: 'Galeries Lafayette',
        desc: 'Client: <span class="_color-white">Galeries Lafayette</span>, Director: <span class="_color-white">BRTHR</span> </br> <small>VFX, Animation</small>',
        poster: require('./assets/galeries_lafayette.jpg'),
        additionalVideosIds: []
    },
    {
        platform: 'vimeo',
        id: 712909417,
        category: workParams.musicVideo,
        title: 'PinkPantheress ft. Willow - Tell Where You Are',
        previewTitle: 'PinkPantheress ft. Willow - Where you are',
        desc: 'Director: <span class="_color-white">BRTHR</span> </br> <small>VFX, Animation</small>',
        poster: require('./assets/pinkpantheress_ft_willow_where_you_are.jpg'),
        additionalVideosIds: []
    },
    {
        platform: 'vimeo',
        id: 811839110,
        category: workParams.reel,
        title: 'HIMIA VFX showreel 2022',
        previewTitle: 'HIMIA Showreel 2022',
        desc: '',
        poster: require('./assets/himia_showreel_2022.jpg'),
        additionalVideosIds: []
    },
    {
        platform: 'vimeo',
        id: 675519711,
        category: workParams.musicVideo,
        title: 'Grimes - Shinigami Eyes',
        previewTitle: 'Grimes - Shinigami Eyes',
        desc: 'Director: <span class="_color-white">BRTHR</span> </br> <small>VFX, Animation</small>',
        poster: require('./assets/grimes_shinigami_eyes.jpg'),
        additionalVideosIds: []
    }
];

const WorkHeader = ({ topic, setTopic, showModalAbout, showModalContact, hideModalAbout, hideModalContact, hideVideoModalVisibility }) => {
    const [hiddenMenuActive, setHiddenMenuActive] = useState(false);

    const navigate = useNavigate();
    const go = topic => {
        const topicType = Object.keys(workParams)
            .find(t => topic === workParams[t])
            ?? null;
        setTopic(topicType);
        if (topic) {
            navigate(`${pages.work}?topic=${workParams[topicType]}`);
        } else {
            navigate(`${pages.work}`);
        }
    };

    const handlerBurgerClick = () => {
        setHiddenMenuActive(!hiddenMenuActive);
        hideModalAbout();
        hideModalContact();
        hideVideoModalVisibility();
    }

    const handlerWorkClick = (click) => {
        click();
        hideModalAbout();
        hideModalContact();
        hideVideoModalVisibility();
    }

    return (
        <>
            <nav className='work-header'>
                <Logo />
                <div className={`work-header-items`}>
                    <div onClick={() => handlerWorkClick(() => go())}
                         className={`work-header-item ${!topic ? 'work-header-item-selected' : ''}`}>
                        All</div>
                    <div className='work-header-item-separator'>|</div>
                    <div onClick={() => handlerWorkClick(() => go(workParams.musicVideo))}
                         className={`work-header-item ${topic === workParams.musicVideo ? 'work-header-item-selected' : ''}`}>
                        Music Video</div>
                    <div className='work-header-item-separator'>|</div>
                    <div onClick={() => handlerWorkClick(() => go(workParams.commercial))}
                         className={`work-header-item ${topic === workParams.commercial ? 'work-header-item-selected' : ''}`}>
                        Commercial</div>
                    <div className='work-header-item-separator'>|</div>
                    <div onClick={() => handlerWorkClick(() => go(workParams.reel))}
                         className={`work-header-item ${topic === workParams.reel ? 'work-header-item-selected' : ''}`}>
                        Reel</div>
                </div>
                <div className={`burger ${hiddenMenuActive ? 'active' : ''}`} onClick={handlerBurgerClick}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </nav>
            <div className={`hidden-menu modal-container-fade ${hiddenMenuActive ? 'active' : '' }`}
                 onClick={() => setHiddenMenuActive(!hiddenMenuActive)}
            >
                <div className="hidden-menu__content">
                    <p onClick={() => go()}>Work</p>
                    <p onClick={showModalAbout}>About</p>
                    <p onClick={showModalContact}>Contact</p>
                </div>
            </div>
        </>
    )
};
const WorkContent = ({ topic, videoModalVisible, setVideoModalVisibility }) => {
    const [contentHeight, setContentHeight] = useState(0);
    const [modalItem, setModalItem] = useState(null);
    const [hovered, setHovered] = useState(-1);
    const [contentDetails, setDetails] = useState([]);

    useEffect(() => {
        let height = window.innerHeight;
        setContentHeight(height);
    }, [])

    useEffect(() => {
        setDetails((contentIds.filter(({ category }) => topic ? category === topic : true)))
    }, [topic])

    // useEffect(() => {
    //     const fetchData = async () => {
    //         const detailsResponses =
    //             await Promise.all(contentIds
    //                 .filter(({ category }) => topic ? category === topic : true)
    //                 .map(({ id }) => fetch(`https://vimeo.com/api/v2/video/${id}.json`)));
    //
    //         const responsesParsed =
    //             await Promise.all(detailsResponses.map(r => r.json()));
    //         setDetails(responsesParsed.reduce((acc, i) => ([...acc, { ...i, poster: contentIds }]), []));
    //     }
    //     fetchData();
    // }, [topic])

    useEffect(() => {
        if (videoModalVisible){
            document.body.classList.add('hide-scroll');
        }else {
            document.body.classList.remove('hide-scroll');
        }
    }, [videoModalVisible]);


    const handlerVideoListItemClick = (id) => {
        const myObject = contentIds.find((obj) => obj.id === id);

        setModalItem(myObject);
        setVideoModalVisibility(true);
    }

    return (
        <div>
            <Scrollbars
                style={{height: contentHeight, maxHeight: contentHeight}}
                renderThumbVertical={props => < div {...props} className="thumb-vertical"/>}
                renderTrackVertical={props => < div {...props} className="track-vertical"/>}>
                <div className='work-content'>
                    {
                        contentDetails.map((c, i) =>
                            <div className='work-content-item' key={`content#${i}`}
                                 onMouseEnter={() => setHovered(i)}
                                 onMouseLeave={() => setHovered(-1)}
                                 onClick={() => handlerVideoListItemClick(c.id)}
                            >
                                <span className="preview-title">{c.previewTitle}</span>
                                <img alt={c.title} src={c.poster} />
                                <span className={`video__play-icon video-carousel__play-icon ${i === hovered ? 'show-on-hover' : ''}`}></span>
                            </div>
                        )}
                </div>
            </Scrollbars>
            {
                !!modalItem && <Modal
                    scrollable
                    isVisible={videoModalVisible}
                    hideModal={() => {setVideoModalVisibility(false); setModalItem(null)}}
                    className={`modal-video ${modalItem.additionalVideosIds.length ? 'multi-videos' : ''}`}
                >
                    <div className="project-box">
                        <p className="project-title">{modalItem.title}</p>
                        <p className="project-desc" dangerouslySetInnerHTML={{__html: modalItem.desc}} />
                        <div className={`iframe-wrapper ${modalItem.platform}`}>
                            {modalItem.platform === 'vimeo' && (
                                <iframe title="vimeo video" src={`https://player.vimeo.com/video/${modalItem.id}`} width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen/>
                            )}
                            {modalItem.platform === 'youtube' && (
                              <iframe title="youtube video" src={`https://www.youtube.com/embed/${modalItem.id}`} width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen/>
                            )}
                        </div>
                    </div>

                    {modalItem.additionalVideosIds.map(id => {
                        return (
                          <div className="project-box">
                              <div className="iframe-wrapper">
                                  {modalItem.platform === 'vimeo' && (
                                    <iframe title="vimeo video" src={`https://player.vimeo.com/video/${id}`} width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen/>
                                  )}
                                  {modalItem.platform === 'youtube' && (
                                    <iframe title="youtube video" src={`https://www.youtube.com/embed/${id}`} width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen/>
                                  )}
                              </div>
                          </div>
                        )
                    })}
                </Modal>
            }

        </div>

    )

}
const WorkFooter = () =>{
    return(
        <footer>
            <div className='work-footer'>
                <Copyright />
            </div>
        </footer>
    );
}



export const WorkPage = () => {
    useVH();
    const [aboutModalVisible, setAboutModalVisibility] = useState(false);
    const [contactsModalVisible, setContactModalVisibility] = useState(false);
    const [videoModalVisible, setVideoModalVisibility] = useState(false);

    const location = useLocation();
    const topic = Object.keys(workParams)
        .find(t => location.search.includes(workParams[t]))
        ?? null;

    const [selectedTopic, setTopic] = useState(topic)

    return <div>
        <WorkHeader
            topic={workParams[selectedTopic]}
            setTopic={setTopic}
            showModalAbout={() => setAboutModalVisibility(true)}
            showModalContact={() => setContactModalVisibility(true)}
            hideModalAbout={() => setAboutModalVisibility(false)}
            hideModalContact={() => setContactModalVisibility(false)}
            hideVideoModalVisibility={() => setVideoModalVisibility(false)}
        />
        <WorkContent
            topic={workParams[selectedTopic]}
            setVideoModalVisibility={() => setVideoModalVisibility(!videoModalVisible)}
            videoModalVisible={videoModalVisible}
        />
        <WorkFooter />

        <ModalAbout
            isVisible={aboutModalVisible}
            hideModal={() => setAboutModalVisibility(false)}
        />
        <ModalContacts
            isVisible={contactsModalVisible}
            hideModal={() => setContactModalVisibility(false)}
        />

    </div>;
};
